import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { format } from 'date-fns';

import { DateTimes } from '@/constants/DateTimes';
import numberFormatter from '@/formatters/ShortNumberConverter';
import { SubscriptionPlaylistDataFormatter } from '@/formatters/SubscriptionDataFormatter';
import useSubscription from '@/hooks/account/useSubscription';
import useLineChartData from '@/hooks/charts/useLineChartData';
import usePlatformArtistMetrics from '@/hooks/metrics/usePlatformArtistMetrics';
import usePlaylistMetrics from '@/hooks/metrics/usePlaylistMetrics';
import { InsightsDataModel, MetricsDataModel, MetricsPlaylistsModel } from '@/models/Metrics';

import LineChartComponent from '../../../charts/LineChart';
import Subscription from '../../../subscription/Subscription';
import Loading from '../../../utility/Loading';
import DateTimeSelector from '../../../utility/microcomponents/DateTimeSelector';
import ViewAllModal from '../../ViewAllModal';

const DeezerInsights = () => {
  const { t } = useTranslation();

  const { isSubscribed } = useSubscription();

  const [featuredPlaylists, setFeaturedPlaylists] = useState<MetricsPlaylistsModel[]>();
  const [chosenInsight, setChosenInsight] = useState<InsightsDataModel>();

  const [selectedStartDate, setSelectedStartDate] = useState<string>();
  const [playlistLoading, setPlaylistLoading] = useState<boolean>(true);

  const { platformArtistMetrics } = usePlatformArtistMetrics({
    platform: 'deezer',
    fromDate: selectedStartDate,
    toDate: DateTimes.yesterdayDate,
  });

  const fansDataMap = useMemo(() => {
    if (!platformArtistMetrics) return;
    return [
      {
        name: 'Fans',
        color: 'deezer',
        key: 'value',
        dataMap: new Map(
          platformArtistMetrics?.fans.map((item) => [format(new Date(item.date), 'yyyy-MM-dd'), item.value])
        ),
      },
    ];
  }, [platformArtistMetrics]);

  const fansChartData = useLineChartData<MetricsDataModel>({
    startDate: selectedStartDate,
    data: fansDataMap,
  });

  const { playlistMetrics } = usePlaylistMetrics({ platform: 'deezer' });

  useEffect(() => {
    if (!playlistMetrics) return;

    if (isSubscribed) {
      setFeaturedPlaylists(playlistMetrics);
    } else {
      setFeaturedPlaylists(SubscriptionPlaylistDataFormatter(playlistMetrics));
    }

    setPlaylistLoading(false);
  }, [isSubscribed, playlistMetrics]);

  const handleDate = useCallback((date: string) => {
    setSelectedStartDate(date);
  }, []);

  return (
    <>
      <ViewAllModal insights={chosenInsight} onClose={() => setChosenInsight(undefined)} />
      <div className="d-flex gap20 flex-wrap mb20">
        <div className="card flex-w60p w100p-lg-down">
          <div className="d-flex jc-space-between mb8">
            <h3>{t('INSIGHTS-PAGE.COMMON.FANS')}</h3>
            <DateTimeSelector dateOutput={handleDate} defaultRange="This year" />
          </div>
          {fansChartData && (
            <h4 className="mb20">
              {fansChartData ? numberFormatter(fansChartData.data[fansChartData.data.length - 1]?.value || 0) : 0}
            </h4>
          )}
          <div>
            <LineChartComponent data={fansChartData} height={350} />
          </div>
        </div>
        <div className="card flex-w40p w100p-lg-down">
          <div className="d-flex jc-space-between mb20">
            <h4>{t('INSIGHTS-PAGE.SPOTIFY.FEATURED-PLAYLISTS')}</h4>
            {isSubscribed ? (
              <p
                className="pt4 cursor-pointer"
                onClick={() => {
                  setChosenInsight({
                    title: 'INSIGHTS-PAGE.SPOTIFY.FEATURED-PLAYLISTS',
                    type: 'featured-playlists',
                    data: featuredPlaylists,
                  });
                }}
              >
                {t('COMMON.VIEW-ALL')}
              </p>
            ) : (
              <Subscription
                type="element"
                element={<img src="/images/logos/subscription-pro-lock-logo.svg" alt="lock" />}
              />
            )}
          </div>{' '}
          {playlistLoading && <Loading size="small" />}
          {!playlistLoading &&
            featuredPlaylists?.slice(0, 5).map((item, index: number) => (
              <div
                key={index}
                className={`d-flex gap20 mt20 list-item campaign ${
                  isSubscribed || index === 0 ? '' : 'subscription-item-blur'
                }`}

                // onClick={() => {
                //   window.open(
                //     `https://open.spotify.com/playlist/${item.playlist.spotifyId}`
                //   );
                // }}
              >
                <img src={item?.playlist.coverImageUrl} alt="" />
                <div className="mt-auto mb-auto">
                  <p>{item?.playlist.name}</p>
                  <p className="text-faded">
                    {numberFormatter(item?.playlist.followers)} {t('INSIGHTS-PAGE.COMMON.FOLLOWERS-LOWER')}
                  </p>
                </div>
                <div className="ml-auto mt-auto mb-auto">{index + 1}</div>
              </div>
            ))}
        </div>
      </div>
      <Subscription type="banner-small" />
    </>
  );
};

export default DeezerInsights;
